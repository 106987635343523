import { useState } from "react";
import GameLoopEventBridge from "./services/GameLoopEventBridge";

import "./App.css";
import { AppDeviceInfo, AppUserInfo } from "./types";

const bridge = new GameLoopEventBridge();
bridge.init();

function App() {
  const [appInfo, setAppInfo] = useState<AppDeviceInfo>({} as AppDeviceInfo);
  const [userInfo, setUserInfo] = useState<AppUserInfo>({} as AppUserInfo);

  const [initStatus, setInitStatus] = useState<string>("")

  const getApp = async () => {
    try {
      const appInfo = await bridge.getApp();
      setAppInfo(appInfo);
    } catch (e) {
      console.error(e);
    }
  };

  const getUser = async () => {
    try {
      const userInfo = await bridge.getUser();
      setUserInfo(userInfo);
    } catch (e) {
      console.error(e);
    }
  };

  const setInitializeStatus = async () => {
    try{
      await bridge.initialize("setInitStatus('initialized')")
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <img
          src="https://www.mlbstatic.com/team-logos/league-on-dark/1.svg"
          className="App-logo"
          alt="logo"
        />
        <h1>MLB Game Loop</h1>
      </header>

      <main className="App-body">
        <h2>Is Native:</h2>
        <p>{bridge?.isNative() ? "Yes" : "No"}</p>

        {/* Native Only */}
        {bridge?.isNative() && (
          <>
            <h2>Methods</h2>
            {/* Initialize */}
            {initStatus ? (
              <>
                <h3>init status:</h3>
                <p>{initStatus}</p>
              </>
            ) : (
              <>
                <h3>initialize()</h3>
                <button onClick={setInitializeStatus}>Initialize User Info</button>
              </>
            )}

            {/* Get App */}
            <h3>getApp()</h3>
            <button onClick={getApp}>Get User's App Information</button>

            <article>
              <table>
                <tr>
                  <th>Property</th>
                  <th>Value</th>
                </tr>
                <tr>
                  <td>OS</td>
                  <td>{appInfo?.os ? appInfo.os : "N/A"}</td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>{appInfo?.name ? appInfo.name : "N/A"}</td>
                </tr>
                <tr>
                  <td>Display Mode</td>
                  <td>{appInfo?.displayMode ? appInfo.displayMode : "N/A"}</td>
                </tr>
                <tr>
                  <td>JS API Version</td>
                  <td>
                    {appInfo?.jsAPIVersion ? appInfo.jsAPIVersion : "N/A"}
                  </td>
                </tr>
                <tr>
                  <td>Build</td>
                  <td>{appInfo?.build ? appInfo.build : "N/A"}</td>
                </tr>
                <tr>
                  <td>Version</td>
                  <td>{appInfo?.version ? appInfo.version : "N/A"}</td>
                </tr>
              </table>
            </article>

            {/* Get User */}
            <h3>getUser()</h3>
            <button onClick={getUser}>Get User Information</button>

            <article>
              <table>
                <caption>Does not display entitlements</caption>
                <tr>
                  <th>Property</th>
                  <th>Value</th>
                </tr>
                <tr>
                  <td>isUserLoggedIn</td>
                  <td>
                    {userInfo?.isUserLoggedIn ? userInfo.isUserLoggedIn : "N/A"}
                  </td>
                </tr>
                <tr>
                  <td>Okta ID</td>
                  <td>{userInfo?.oktaId ? userInfo.oktaId : "N/A"}</td>
                </tr>
                <tr>
                  <td>Followed Teams</td>
                  <td>
                    {userInfo?.followedTeams ? userInfo.followedTeams : "N/A"}
                  </td>
                </tr>
                <tr>
                  <td>Followed Players</td>
                  <td>
                    {userInfo?.followedPlayers
                      ? userInfo.followedPlayers
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <td>Favorite Team</td>
                  <td>
                    {userInfo?.favoriteTeam ? userInfo.favoriteTeam : "N/A"}
                  </td>
                </tr>
                <tr>
                  <td>Is Premium</td>
                  <td>{userInfo?.isPremium ? userInfo.isPremium : "N/A"}</td>
                </tr>
                <tr>
                  <td>US Privacy</td>
                  <td>{userInfo?.usPrivacy ? userInfo.usPrivacy : "N/A"}</td>
                </tr>
              </table>
            </article>

            {/* Show Alert */}
            <h3>showAlert()</h3>
            <button onClick={() => bridge.showAlert("Native Alert")}>
              Show Alert
            </button>

            {/* Launch External URL */}
            <h3>launchExternal(url)</h3>
            <button onClick={() => bridge.launchExternal("https://mlb.com")}>
              Open MLB.com
            </button>

            {/* Close */}
            <h3>close()</h3>
            <button onClick={() => bridge.close()}>Close Webview</button>

            {/* Invite To Group */}
            <h3>inviteToGroup(gameId, groupId)</h3>
            <button onClick={() => bridge.inviteToGroup("BeatTheStreak2022", "TestGroupId")}>
              Invite Group
            </button>
          </>
        )}

        {!bridge?.isNative() && (
          <>
            {/* Web */}
            <h3>Games</h3>
            <ul>
              <li>
                <strong>Pickle</strong>:{" "}
                <a href="mlbplay://com.mlbam.BTS/game?gameId=Pickle2022">
                  Click here to open Pickle
                </a>
              </li>
            </ul>
          </>
        )}
      </main>
    </div>
  );
}

export default App;
