import { AppDeviceInfo, AppUserInfo } from "../types";

export type MobileEventBridge = Pick<
  GameLoopEventBridge,
    "initialize"
  | "showAlert"
  | "launchExternal"
  | "close"
  | "inviteToGroup"
  | "getUser"
  | "getApp"
>;

declare global {
  interface Window {
    mlbNative: MobileEventBridge;
  }

  const mlbNative: MobileEventBridge;
}

export default class GameLoopEventBridge {
  nativeBridge: MobileEventBridge;

  constructor() {
    this.nativeBridge = null;
  }

  public async init() {
    try {
      await this.setNativeBridge();
    } catch (error) {
      throw new Error(`Initialization Error: ${error?.message}`);
    }
  }

  /*
   * Checks the user agent to determine if the app is running on a mobile device
   */
  public isNative(): boolean {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  /*
   * Attempts to set the native bridge using the mlbNative class exposed via the JS injection into the WebView
   */
  private setNativeBridge() {
    if (this.isNative()) {
      let timer;
      let timesCalled = 0;
      const checkState = () => {
        if (
          document.readyState === "complete" &&
          typeof mlbNative !== "undefined" &&
          !this.nativeBridge
        ) {
          this.nativeBridge = mlbNative;
          clearTimeout(timer);
        } else {
          timesCalled = timesCalled + 1;

          if (timesCalled === 5) {
            clearTimeout(timer);
            throw new Error("Native bridge was not set");
          }

          timer = setTimeout(checkState, 200);
        }
      };

      checkState();
    }
  }

  public initialize(callback: String) {
    if (this.isNative()) {
      this.nativeBridge?.initialize(callback);
    }
  }

  /*
   * Triggers a native alert message for iOS and Android
   */
  public showAlert(message: string) {
    if (this.isNative()) {
      this.nativeBridge?.showAlert(message);
    }
  }

  /*
   * Launches an external browser window for iOS and Android
   */
  public launchExternal(url: string) {
    if (this.isNative()) {
      this.nativeBridge?.launchExternal(url);
    }
  }

  /*
   * Closes the WebView and navigates the user back to it's previous context
   */
  public close() {
    if (this.isNative()) {
      this.nativeBridge?.close();
    }
  }

  /*
   * Opens the inviteToGroup modal for a game loop
   */
  public inviteToGroup(gameId: string, groupId: string) {
    if (this.isNative()) {
      this.nativeBridge?.inviteToGroup(gameId, groupId);
    }
  }

  public async getUser(): Promise<AppUserInfo> {
    return this.nativeBridge
      ? this.nativeBridge.getUser()
      : ({} as AppUserInfo);
  }

  public async getApp(): Promise<AppDeviceInfo> {
    return this.nativeBridge
      ? this.nativeBridge.getApp()
      : ({} as AppDeviceInfo);
  }
}
